<template>
  <section class="main">
    <div class="section dark top dark-red">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>Bageri & Konditori i Trollhättan - Dagmar Carlssons Hembageri Eftr.</h1>
          </div>
        </div>
      </div>
    </div>
    <section class="dark red">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 owl-theme">
            <p>Sidan hittades inte</p>
          </div>
        </div>
      </div>
    </section>
    <section class="content-area light">
    </section>
  </section>
</template>

<script>

export default {
  metaInfo: {
    title: 'Sidan hittades inte | Bageri & Konditori i Trollhättan | Dagmar Carlssons Hembageri Eftr. | dagmarcarlssons.se',
  }
}
</script>

<style>

</style>